.header h2 {
  margin-top: 16px;
  margin-right: 0;
  margin-bottom: 16px;
  margin-left: 0;
}

.row {
  display: flex;
  align-items: center;
}

.row-container {
  display: flex;
  padding: 10px;
  border-radius: 20px;
  background: #F7F7F7;
  width: 100%;
  border: 1px solid #757575;
}

.header-row-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
}

.modal-container {
}

.modal-content {
}

.checkbox-container {
}

.store-item-left {
  flex: 0 0 auto;
  margin-right: 10px;
}

.store-info {
  flex: 1;
}

.store-list-container {
  overflow: auto;
}

.store-item {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 8px;
  border: 1px solid #dddddd;

  border-radius: 20px;
  max-width: 350px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
}

.store-item-empty {
  width: 364px;
}

.store-item:hover {
  background: #f7f7f7;
  cursor: pointer;
}

.store-item.selected {
  background-color: #EEEEEE; /* Change this to the desired background color */
}

.store-form-container-wrapper {
}

.autocomplete-container {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}

.autocomplete-input {
  width: 280px;
  border: none;
  background-color: transparent;
  font-size: 1rem;
}

.autocomplete-input:focus {
  border: none;
  outline: none;
}

.store-list-container {
  max-height: calc(100vh - 120px);
  overflow: hidden;
}

.store-list-data-container {
  max-height: 100%;
  overflow-y: scroll; /* Always show the vertical scrollbar */
  padding-right: 5px;
}

.store-list-container-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-container {
  padding-left: 12px;
  padding-right: 12px;
}

.store-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.button-container {
  padding: 2px;
}

.button-container-input {
  padding-right: 4px;
  padding-left: 4px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.button-rounded-black {
  border-radius: 50px;
  padding: 8px 16px;
  background-color: #000;
  color: white;
  border: none;
  border: 1px solid #000;
  cursor: pointer;
}

.button-rounded-black:hover {
  background-color: #555;
  border: 1px solid #555;
}

.button-rounded-black:active {
  background-color: #000;
}

.button-expand-outline {
  border-radius: 50px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid lightgray;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.button-expand-outline:hover {
  background-color: #555555;
}

.button-expand-outline:active {
  background-color: #f5f5f5;
}

.button-expand-outline-show {
  display: none;
  border-radius: 50px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid lightgray;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.button-expand-outline-show:hover {
  background-color: #f5f5f5;
}

.button-expand-outline-show:active {
  background-color: #f5f5f5;
}

.button-rounded-outline {
  border-radius: 50px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid lightgray;
  cursor: pointer;
  margin-right: 4px;
}

.button-rounded-outline:hover {
  background-color: #f5f5f5;
}

.button-rounded-outline:active {
  background-color: #f5f5f5;
}

.header {
  text-align: center;
}

.filters-container {
  padding-top: 0;
  /* padding-right: 10px; */
  /* padding-bottom: 10px; */
  /* padding-left: 10px; */
}

.distance-buttons {
  display: flex;
  justify-content: center;
  gap: 2px;
  margin-top: 10px;
}

.distance-button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.distance-button:hover {
  background-color: #f5f5f5;
}

.distance-button.selected {
  border: 1px solid #555;
  background-color: #555;
  color: #fff;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon-small {
  width: 16px;
  height: 16px;
}

/* Radio */
.checkbox-container {
  display: flex;
  flex-direction: column;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between the checkbox and the label text */
  font-size: 16px; /* Adjust font size as needed */
  padding: 4px;
}

.checkbox-container label:hover {
  background: #f7f7f7;
}

/* .round-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #007bff;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

.round-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #007bff;
  transform: translate(-50%, -50%);
} */

/* Check box */
.round-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #2189FF; /* Blue border */
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  /* transition: background-color 0.3s ease; */
}

.round-checkbox:checked {
  background-color: #2189FF; /* Blue background when checked */
}

.round-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 45%;
  left: 50%;
  width: 4px;
  height: 8px;
  border: solid #fff; /* White checkmark */
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.store-list-data-container {
  display: none;
}

.store-list-data-container.expanded-mobile {
  display: block;
  padding-top: 6px;
}

.store-list-data-container.expanded-desktop {
  display: block;
}

/* Add these styles to handle visibility */
.store-list-data-container.hidden {
  display: none;
}

.store-list-data-container.visible {
  display: block;
}

.modal-container {
  display: none;
  padding-left: 14px;
  padding-right: 14px;
}

.modal-container.show {
  display: block;
}

.styled-border {
  border-bottom: 1px solid #DDDDDD; /* White checkmark */
  margin-top: 14px;
  margin-bottom: 14px;
}

.scroll-button {
  display: none;
}

@media (max-width: 767px) {
  .modal-container {
  }
  .modal-content {
  }
  .row {
    max-width: none;
  }
  .store-list-container {
    padding-top: 10px;
    padding-bottom: 6px;
    overflow: auto;
    background-color: white;
  }
  .store-list-container-wrapper {
    height: auto;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .store-item {
    max-width: none;
  }
  .button-expand-outline-show {
    display: block;
  }
  .scroll-button {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #2189FF;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 999;
    margin-left: -25px; /* Half of the button width */
  }
}
