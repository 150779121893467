.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.map {
  flex: 1;
}

body {
  font-family: "SamsungOne", sans-serif;
}

.store-list {
  padding-left: 8px;
  padding-right: 8px;
}

@font-face {
  font-display: swap;
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: normal;
  src: local("SamsungOne"), url("./SamsungOne-400.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "SamsungOne";
  font-style: normal;
  font-weight: bold;
  src: local("SamsungOne"), url("SamsungOne-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: bold;
  src: local("SamsungSharpSans"),
    url("./SamsungSharpSansBd.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "SamsungSharpSans";
  font-style: normal;
  font-weight: normal;
  src: local("SamsungSharpSans"),
    url("./SamsungSharpSansBd.woff2") format("woff2");
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    /* flex-direction: column-reverse; */
  }

  .store-list {
    /* width: 100%; */
    height: auto;
  }

  .map {
    width: 100%;
    height: auto;
  }
}
