/* https://github.com/visgl/react-google-maps/issues/378 */
.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
}

.info {
  padding: 6px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.icon.right {
  padding-right: 6px;
}

.icon-small.right {
  padding-right: 6px;
}

.centered-content {
  display: flex;
  align-items: center;
}

a.main {
  color: #006BEA; /* Inherit the color from the parent element */
  text-decoration: none;
}

a.notmain {
  color: #006BEA; /* Inherit the color from the parent element */
  text-decoration: none;
}